import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "../images/mdtech-logo.png";
import IconMenu from "../images/icons/interface/menu.svg";
import IconCross from "../images/icons/interface/cross.svg";
import IconCard from "../images/icons/theme/shopping/credit-card.svg";
import IconChart from "../images/icons/theme/shopping/chart-bar-1.svg";


const Header = ({
  hideHeader,
  isHeaderDark = false,
  headerBgClass = "bg-white",
  isScrollUp,
  isTop,
  isOpenMobileMenu
}) => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);

  const toggleHandleMobileMenu = () => {
    setToggleMobileMenu(!toggleMobileMenu);
    isOpenMobileMenu(!toggleMobileMenu);
  };

  const getHeaderClass = () => {
    if (isHeaderDark) {
      if (toggleMobileMenu) {
        return "navbar-dark bg-primary navbar--mobile-show";
      } else if (!toggleMobileMenu) {
        return `navbar-dark ${headerBgClass}`;
      }
    } else {
      if (toggleMobileMenu) {
        return "navbar-dark bg-primary navbar--mobile-show";
      }
      return `navbar-light ${headerBgClass}`;
    }
  };

  return (
    <header className="navbar-container">
      <nav
        className={`navbar navbar-expand-lg ${getHeaderClass()} 
            ${
              isScrollUp
                ? `navbar--fixed animated fadeIn faster ${!isTop ? "delay-0s" : ""}`
                : typeof window !== "undefined" && window.pageYOffset > 73
                  ? "navbar--hidden"
                  : ""
            }
          `}
        
        
        // ${
        //   isScrollUp
        //     ? `navbar--fixed animated fadeIn faster ${!isTop ? "delay-1s" : ""}`
        //     : typeof window !== "undefined" && window.pageYOffset > 73
        //     ? "navbar--hidden"
        //     : ""
        // }


        
      >
        <div className="container h-100">
          <Link to="/" className="navbar-brand fade-page" aria-label="mdtech">
          <img src={Logo} alt="Media Data Lake" />
            {/* {isHeaderDark || toggleMobileMenu ? <LogoWhite /> : <Logo />} */}
          </Link>

          <button className="navbar-toggler" type="button" aria-label="toggle navbar">
            {toggleMobileMenu ? (
              <IconCross
                className="icon icon-md bg-primary-3 navbar-toggler-close"
                onClick={toggleHandleMobileMenu}
              />
            ) : (
              <IconMenu
                className="icon icon-md bg-primary-3 navbar-toggler-open"
                onClick={toggleHandleMobileMenu}
              />
            )}
          </button>

          {!hideHeader && (
            <div className="navbar--desktop navbar-collapse justify-content-end collapse">
              <div className="py-2 py-lg-0">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown area-hover">
                    <Link to="/#" className="nav-link" style={{ pointerEvents: "none" }}>
                      How it works
                      <i className="dd-icon"></i>
                    </Link>
                    <div className="dropdown-menu">
                      <div className="dropdown-menu-content">
                        <ul>
                        <li>
                            <Link to="/#" className="list-group-item-action">
                              <i>
                                <IconChart className="icon icon-md bg-white" />
                              </i>
                              <div>
                              <span>The Process</span>
                                <small>Cras metus. Sed aliquet risus a tortor.</small>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/#" className="list-group-item-action">
                              <i>
                                <IconCard className="icon icon-md bg-white" />
                              </i>
                              <div>
                                <span>The Process</span>
                                <small>Cras metus. Sed aliquet risus a tortor.</small>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to="/invest" className="nav-link">
                      Invest
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about-us" className="nav-link">
                    About MDT
                    </Link>
                  </li>

                  {/* <li className="nav-item" style={{marginTop: "-2px"}}>
                      <SearchNavButton/>
                  </li> */}
                </ul>
              </div>
              <Link id="freeconsultation" to="/#" className="btn btn-primary ml-lg-3">
                Contact
              </Link>
            </div>
          )}
          <div className={`d-lg-none mobile-menu ${toggleMobileMenu ? "show " : ""}`}>
            <div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/#" className="nav-link">
                    The Process
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/#" className="nav-link">
                  The Process
                  </Link>
                </li>
                <li className="nav-item">
                    <Link to="/invest" className="nav-link">
                      Invest
                    </Link>
                  </li>
                <li className="nav-item">
                  <Link to="/about-us" className="nav-link">
                    About mdtech
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p>Maximize Profit</p>
              <Link to="/#" className="btn btn-lg btn-white">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
