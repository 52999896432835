import React from "react";
import { Link } from "gatsby";

import Logo from "../images/logo-white.svg";
// import LogoWhite from "../images/logo-white.svg";

const Footer = ({ footerBgClass = "bg-primary-alt" }) => (
  <footer id="footer" className={footerBgClass}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 order-last order-lg-first nav-social">
          <a href="https://mdtech.uk" aria-label="Media data technology">
            {footerBgClass === "bg-primary-3" ? <Logo /> : <Logo />}
          </a>

          <div>
            <p>Connect</p>
            <ul className="nav">
              <li>
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://observablehq.com/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                 Observable
                </a>
              </li>
            </ul>
          </div>

          <div className="nav-bottom">
            <span>©{new Date().getFullYear()} All Rights Reserved.</span>
            <Link to="/#">Privacy Policy</Link>
          </div>
        </div>

        <div className="col-12 col-lg-2 nav-inner">
          <ul className="nav flex-column">
            <li className="nav-item align-items-center mb-2 opacity-30">
              <strong>Solutions</strong>
            </li>
            <li className="nav-item">
              <Link to="/#">The Process</Link>
            </li>
            <li className="nav-item">
            <Link to="/#">How it works</Link>
            </li>
            <li className="nav-item">
            <Link to="/#">Investments</Link>
            </li>
          </ul>
        </div>

        <div className="col-12 col-lg-2 nav-inner">
          <ul className="nav flex-column">
            <li className="nav-item align-items-center mb-2 opacity-30">
              <strong>Links</strong>
            </li>

            <li className="nav-item align-items-center">
              <Link className="align-items-center" to="/#">
                Contact MDT <span className="badge badge-primary">3</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/#">About Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
